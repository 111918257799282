import styled from 'styled-components';

export const PAymentTermSC = styled.div`
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 959px) {
    padding: 15px 5px;
  }
  @media (max-width: 375px) {
    padding: 15px 0;
  }
  p {
    margin-left: 5px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    margin: 0;
  }
  .section {
    @media (max-width: 850px) {
      width: 100%;
      margin: 0.5em 0 1em 1.8em;
    }
  }
  .width {
    width: 20em !important;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .margin {
    margin-left: -4px;
    @media (max-width: 850px) {
      width: 100%;
      margin: 0.5em 0 1em 1.5em;
    }
  }
  @media (max-width: 850px) {
    flex-wrap: wrap !important;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    margin: 0;
  }
  .width {
    width: 20em !important;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  @media (max-width: 1120px) {
    align-items: flex-start;
  }
  @media (max-width: 850px) {
    flex-wrap: wrap;
  }
`;

export const PriceTermSC = styled.div`
  width: 100%;
  @media (max-width: 850px) {
    margin: 0.5em 0 1em 2em;
    width: 100%;
  }
`;

export const PriceTerm = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5em;
  cursor: pointer;
  h6 {
    margin: 0;
    font-weight: bold;
  }
`;
