import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';
import { useMutation } from 'react-query';
import { POST_BM_THANKYOU } from './queries';
import queryString from 'query-string';
import { getLocalStorageValues } from 'constants/local-storage';
import ThankYou from './Thankyou';

const BmThankYou = () => {
  const { promoBmCode } = getLocalStorageValues();
  const history = useHistory();
  const { mutate } = useMutation(POST_BM_THANKYOU);
  let params = queryString.parse(history?.location?.search);

  useEffect(async () => {
    localStorage.removeItem('bm_cart');
    localStorage.removeItem('remember_bm_cart_login');
    await mutate({
      paypaltoken: params.token,
      paypalpayerid: params.PayerID || '',
    });
  }, []);

  return (
    <ThankYou
      handleClick={() => history.replace(navLinks.public_checkout_bm.link + promoBmCode)}
      buttonText={'public/checkout/vs/thankyou.string3'}
      heading={'public/checkout/vs/thankyou.string1'}
      paragraph={'public/checkout/vs/thankyou.string2'}
    />
  );
};

export default BmThankYou;
