import { BASE_URL } from 'constants/env';
import axios from 'axios';

export const POST_THANKYOU = async (e) => {
  const res = await axios.post(BASE_URL + `/public/checkout/vs/thankyou`, { ...e });
  return res.data;
};

export const POST_BM_THANKYOU = async (e) => {
  const res = await axios.post(BASE_URL + `/public/checkout/bm/thankyou`, { ...e });
  return res.data;
};
