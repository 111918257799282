/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import * as Styles from './Invoices.style';
import Heading from 'components/Heading/Heading';
import { Trans, useTranslation } from 'react-i18next';
import { H6, P } from 'components/reusable-css';
import moment from 'moment';
import CustomButton from 'components/common/custom-button';
import { Icon } from 'semantic-ui-react';
import { Hidden } from '@material-ui/core';
import { UpperCaseWord } from 'assets/css/common-styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from 'constants/env';
import DialogBox from 'components/dialog/Dialog';
import Loading from 'components/common/loading/Loading';
import LoadingImage from 'assets/images/loading.svg';
import html2pdf from 'html2pdf.js';
import { scrollToSection } from 'utils';

const handleService = (data) =>
  data == 'ccloudres'
    ? 'string60'
    : data == 'credit'
    ? 'string61'
    : data == 'creditstaff'
    ? 'string62'
    : data == 'domain'
    ? 'string63'
    : data == 'cert'
    ? 'string64'
    : data == 'domaintr'
    ? 'string65'
    : data == 'weebly'
    ? 'string66'
    : data == 'onetime'
    ? 'string67'
    : data == 'support'
    ? 'string68'
    : data == 'band'
    ? 'string69'
    : data == 'shared'
    ? 'string70'
    : data == 'dedi'
    ? 'string71'
    : null;
const handlePayment = (data) =>
  data == 'cc'
    ? 'account/billing_info.string77'
    : data == 'paypal'
    ? 'account/billing_info.string78'
    : data == 'Alipay'
    ? 'account/billing_info.string79'
    : data == 'cashapp'
    ? 'account/billing_info.string80'
    : data == 'other'
    ? 'account/billing_info.string85'
    : null;

const Invoices = ({ invoiceData, onShowModal }) => {
  const [isCheck, setIsCheck] = useState({});
  const [isAllCheck, setIsAllCheck] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isPdfMultiLoading, setIsPdfMultiLoading] = useState(false);
  const [isPrintMultiLoading, setIsPrintMultiLoading] = useState(false);
  const [savePdf, setSavePdf] = useState('');
  const [invoiceId, setInvoiceId] = useState(0);
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const state = useSelector((state) => state.user);

  const createMarkup = () => {
    return { __html: savePdf };
  };

  const handleGenerateHtml = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${BASE_URL}/billing/invoice/${id}`);
      await setSavePdf(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOpenDropdown = (id) => {
    setIsOpen(true);
    setInvoiceId(id);
    handleGenerateHtml(id);
  };

  const handleDownloadPdf = async (id) => {
    setIsPdfLoading(true);
    try {
      const { data } = await axios.get(`${BASE_URL}/billing/invoice/${id}/pdf`);
      const stringtoHtmlInvoice = document.createElement('div');
      stringtoHtmlInvoice.innerHTML = data;
      // Wait for all images to load
      const images = stringtoHtmlInvoice.getElementsByTagName('img');
      const loadPromises = Array.from(images).map((img) => {
        img.crossOrigin = 'anonymous';
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      await Promise.all(loadPromises);
      const options = {
        margin: 0.5,
        filename: `invoices-${id}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, logging: true, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'legacy'], before: 'div[style*="page-break-before: always;"]' },
      };
      await html2pdf().from(stringtoHtmlInvoice).set(options).toPdf().save();
      setIsPdfLoading(false);
    } catch (error) {
      setIsPdfLoading(false);
    }
  };

  const handleDownloadMultiPdf = async () => {
    setIsPdfMultiLoading(true);
    try {
      const multiInvoiceHtml = document.createElement('div');
      const selectedInvoceIDs = Object.keys(isCheck);
      for (let index = 0; index < selectedInvoceIDs.length; index++) {
        const stringtoMultiHtmlInvoice = document.createElement('div');
        const { data } = await axios.get(`${BASE_URL}/billing/invoice/${selectedInvoceIDs[index]}/pdf`);
        stringtoMultiHtmlInvoice.innerHTML = data;
        multiInvoiceHtml.appendChild(stringtoMultiHtmlInvoice);
      }
      // Wait for all images to load
      const images = multiInvoiceHtml.getElementsByTagName('img');
      const loadPromises = Array.from(images).map((img) => {
        img.crossOrigin = 'anonymous';
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      await Promise.all(loadPromises);
      const options = {
        margin: 0.5,
        filename: `invoices.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, logging: true, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'legacy'], before: 'div[style*="page-break-before: always;"]' },
      };
      await html2pdf().from(multiInvoiceHtml).set(options).toPdf().save();
      setIsCheck({});
      setIsAllCheck(0);
      setIsPdfMultiLoading(false);
    } catch (error) {
      setIsPdfMultiLoading(false);
    }
  };

  const handleMultiPrint = async () => {
    setIsPrintMultiLoading(true);
    try {
      const multiInvoiceHtml = document.createElement('div');
      const selectedInvoceIDs = Object.keys(isCheck);
      for (let index = 0; index < selectedInvoceIDs.length; index++) {
        const stringtoMultiHtmlInvoice = document.createElement('div');
        const { data } = await axios.get(`${BASE_URL}/billing/invoice/${selectedInvoceIDs[index]}/pdf`);
        stringtoMultiHtmlInvoice.innerHTML = data;
        multiInvoiceHtml.appendChild(stringtoMultiHtmlInvoice);
      }
      // Wait for all images to load
      const images = multiInvoiceHtml.getElementsByTagName('img');
      const loadPromises = Array.from(images).map((img) => {
        img.crossOrigin = 'anonymous';
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      await Promise.all(loadPromises);
      const options = {
        margin: 0.5,
        filename: `invoices.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, logging: true, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'legacy'], before: 'div[style*="page-break-before: always;"]' },
      };
      await html2pdf()
        .set(options)
        .from(multiInvoiceHtml)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const pdfBlob = pdf.output('blob');
          const blobUrl = URL.createObjectURL(pdfBlob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.onload = () => {
            iframe.contentWindow.print();
          };
        });
      setIsCheck({});
      setIsAllCheck(0);
      setIsPrintMultiLoading(false);
    } catch (error) {
      setIsPrintMultiLoading(false);
    }
  };

  const handleScrollToPayment = () => {
    onShowModal();
    document.getElementById('add_funds_vs_reserved').classList.add('focused-payment');
    scrollToSection('add_funds_vs_reserved');
  };

  const handleCheckBox = (e, data) => {
    let is_check = isCheck[e.target.value] == 1;
    setIsCheck({ ...isCheck, [data.id]: is_check ? 0 : e.target.value == data.id ? 1 : 0 });
  };

  const handleAllCheckBox = (value) => {
    setIsAllCheck(value.target.value == 1 ? 0 : 1);
    let isCheckedArr = {};
    if (value.target.value == 0) {
      for (const key in invoiceData?.invoices) {
        isCheckedArr[invoiceData?.invoices[key].id] = 1;
      }
    } else {
      for (const key in invoiceData?.invoices) {
        isCheckedArr[invoiceData?.invoices[key].id] = 0;
      }
    }
    setIsCheck(isCheckedArr);
  };

  useEffect(() => {
    for (const key in isCheck) {
      if (isCheck[key] == 0) {
        delete isCheck[key];
      }
    }
  }, [isCheck]);

  return (
    <Styles.InvoicesSC background={state?.ui?.show_funds_deposit_section != 0}>
      <Heading name={'account/billing_info.string54'}>
        <Hidden only={['sm', 'xs']}>
          <>
            <Styles.Header>
              <H6 style={{ width: '15%', paddingRight: '1em' }} className="d-flex align-items-center">
                <Styles.Input
                  type={'checkbox'}
                  name={'check'}
                  id={isAllCheck}
                  checked={isAllCheck}
                  value={isAllCheck}
                  onChange={(e) => handleAllCheckBox(e)}
                />
                {t('account/billing_info.string55')}
              </H6>
              <H6 style={{ width: '20%', paddingRight: '1em' }}>{t('account/billing_info.string56')}</H6>
              <H6 style={{ width: '15%', paddingRight: '2em', textAlign: 'right' }}>
                {t('account/billing_info.string57')}
              </H6>
              <H6 style={{ width: '15%' }}>{t('account/billing_info.string58')} </H6>
              <H6 style={{ width: '20%', textAlign: 'right', paddingLeft: '1em' }}>
                {t('account/billing_info.string59')}{' '}
              </H6>
              <H6 style={{ width: '15%' }} />
            </Styles.Header>
            <Styles.Content>
              {invoiceData?.invoices?.map((data) => (
                <Styles.Rows key={data.id}>
                  <div style={{ width: '15%', paddingRight: '1em' }} className="d-flex align-items-center">
                    <Styles.Input
                      type={'checkbox'}
                      name={'check'}
                      id={data.id}
                      checked={isCheck[data.id]}
                      value={data.id}
                      onChange={(e) => handleCheckBox(e, data)}
                    />
                    <H6 onClick={() => handleOpenDropdown(data.id)} className="ml-1 modal-open">
                      {data.id}
                    </H6>
                  </div>
                  <H6 style={{ width: '20%', paddingRight: '1em' }}>
                    {t(`account/billing_info.${handleService(data.service_category)}`)}
                  </H6>
                  <H6 style={{ width: '15%', paddingRight: '2em', textAlign: 'right' }}>
                    {moment(data.date).format('ll')}
                  </H6>
                  <H6 style={{ width: '15%' }}>{t(handlePayment(data.payment_type))}</H6>
                  <H6 style={{ width: '20%', textAlign: 'right' }}>
                    <Trans
                      i18nKey="account/billing_info.string72"
                      values={{
                        price: data?.amount,
                        currency: userAccount.account_currency,
                        currency_symbol: userAccount.account_currency_symbol,
                      }}
                      components={{ uppercase: <UpperCaseWord /> }}
                    />
                  </H6>
                  <div style={{ width: '15%', paddingLeft:'1em' }} className="d-flex justify-content-end align-items-center">
                    {data.status != 'paid' ? (
                      <CustomButton
                        onClick={() => handleScrollToPayment()}
                        buttonStyles={{ marginRight: '1em', cursor: 'pointer' }}
                        title={`account/billing_info.string74`}
                      />
                    ) : (
                      <H6 style={{ width: '6em', textAlign: 'center', marginRight: '1em' }}>
                        {t('account/billing_info.string73')}
                      </H6>
                    )}
                    <div
                      onClick={() => {
                        setInvoiceId(data.id);
                        handleDownloadPdf(data.id);
                      }}
                    >
                      {isPdfLoading && data.id == invoiceId && !isOpen ? (
                        <img src={LoadingImage} width={20} />
                      ) : (
                        <Icon style={{ cursor: 'pointer' }} name="download" color="orange" />
                      )}
                    </div>
                  </div>
                </Styles.Rows>
              ))}
            </Styles.Content>
          </>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <Styles.MobileContent>
            {invoiceData?.invoices?.map((data) => (
              <Styles.MobileRows key={data.id}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Styles.Input
                    type={'checkbox'}
                    name={'check'}
                    id={data.id}
                    checked={isCheck[data.id]}
                    value={data.id}
                    onChange={(e) => handleCheckBox(e, data)}
                  />
                  <div className="ml-1 mt-1">
                    <Styles.MobileLine>
                      <H6 className="width">{t('account/billing_info.string55')}:</H6>
                      <H6 className="modal-open" onClick={() => handleOpenDropdown(data.id)}>
                        {data.id}
                      </H6>
                    </Styles.MobileLine>
                    <Styles.MobileLine>
                      <H6 className="width">{t('account/billing_info.string56')}:</H6>
                      <H6>{t(`account/billing_info.${handleService(data.service_category)}`)}</H6>
                    </Styles.MobileLine>
                    <Styles.MobileLine>
                      <H6 className="width">{t('account/billing_info.string57')}:</H6>
                      <H6>{moment(data.date).format('ll')}</H6>
                    </Styles.MobileLine>
                    <Styles.MobileLine>
                      <H6 className="width">{t('account/billing_info.string58')}:</H6>
                      <H6>{t(handlePayment(data.payment_type))}</H6>
                    </Styles.MobileLine>
                    <Styles.MobileLine>
                      <H6 className="width">{t('account/billing_info.string59')}:</H6>
                      <H6>
                        <Trans
                          i18nKey="account/billing_info.string72"
                          values={{
                            price: data?.amount,
                            currency: userAccount.account_currency,
                            currency_symbol: userAccount.account_currency_symbol,
                          }}
                          components={{ uppercase: <UpperCaseWord /> }}
                        />
                      </H6>
                    </Styles.MobileLine>
                  </div>
                </div>
                <Styles.MobileButtonIcon>
                  {data.status != 'paid' ? (
                    <CustomButton
                      onClick={() => handleScrollToPayment()}
                      buttonStyles={{ cursor: 'pointer' }}
                      title={`account/billing_info.string74`}
                    />
                  ) : (
                    <H6 style={{ width: '8em', textAlign: 'end', marginRight: '1em' }}>
                      {t('account/billing_info.string73')}
                    </H6>
                  )}
                  <div
                    onClick={() => {
                      setInvoiceId(data.id);
                      handleDownloadPdf(data.id);
                    }}
                  >
                    {isPdfLoading && data.id == invoiceId && !isOpen ? (
                      <img src={LoadingImage} width={20} />
                    ) : (
                      <Icon name="download" color="orange" />
                    )}
                  </div>
                </Styles.MobileButtonIcon>
              </Styles.MobileRows>
            ))}
          </Styles.MobileContent>
        </Hidden>

        <Styles.BottomContainer>
          <Styles.DownloadAll
            isChecked={Object.keys(isCheck).length > 0}
            onClick={() => Object.keys(isCheck).length > 0 && handleDownloadMultiPdf()}
          >
            <Icon name="download" />
            <P>{t('account/billing_info.string75')}</P>
            {isPdfMultiLoading && <img src={LoadingImage} width={25} className="ml-2" />}
          </Styles.DownloadAll>
          <Styles.DownloadAll
            isChecked={Object.keys(isCheck).length > 0}
            onClick={() => Object.keys(isCheck).length > 0 && handleMultiPrint()}
          >
            <Icon name="print" />
            <P>{t('account/billing_info.string84')}</P>
            {isPrintMultiLoading && <img src={LoadingImage} width={25} className="ml-2" />}
          </Styles.DownloadAll>
        </Styles.BottomContainer>
      </Heading>
      <DialogBox width="lg" open={isOpen} onClose={() => setIsOpen(!isOpen)}>
        <div style={{ width: '700px', height: '100%', paddingBottom: '2em' }}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Styles.DialogLink>
                <div onClick={() => setIsOpen(false)}>
                  <Icon name="close" color="orange" size="large" />
                </div>
                <div className="download">
                  {isPdfLoading && <img src={LoadingImage} width={30} className="mr-2" />}
                  <P onClick={() => handleDownloadPdf(invoiceId)} className="download-link">
                    {t('account/billing_info.string83')}
                  </P>
                </div>
              </Styles.DialogLink>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </>
          )}
        </div>
      </DialogBox>
    </Styles.InvoicesSC>
  );
};

export default Invoices;
