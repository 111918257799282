import React from 'react';
import { BgLogin, LoginBox, LoginForm, Logo, LoginContainer } from './ThankYou.style';
import CustomButton from 'components/common/custom-button';
import { H1, P } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ThankYou = ({ handleClick, heading, paragraph, buttonText }) => {
  const { t } = useTranslation();
  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <Icon name="check circle" />
            </Logo>
            <H1>{t(heading)}</H1>
            <P>{t(paragraph)}</P>
            <CustomButton
              onClick={() => handleClick()}
              buttonStyles={{ width: '100%' }}
              type="submit"
              title={buttonText}
            />
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default ThankYou;
