import React from 'react';
import * as Styles from './style';
import Heading from 'components/Heading/Heading';
import { H6, P } from 'components/reusable-css';
import moment from 'moment';
import Checkbox from 'components/common/radio-button/RadioButton';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';
import { useMutation } from 'react-query';
import { BILLING_PAYMENT_TERMS } from 'components/manage-vm/queries';
import LoadingImage from 'assets/images/loading.svg';
import { CustomHook } from 'utils/custom-hook';

const paymentTermArr = [
  { id: 1, name: 'vs/instance.string181', termId: 1 },
  { id: 12, name: 'vs/instance.string182', termId: 12 },
  { id: 24, name: 'vs/instance.string206', termId: 24 },
  { id: 36, name: 'vs/instance.string207', termId: 36 },
];

const PaymentTerms = ({ data, setPaymentTerm, paymentTerm, setIschanged }) => {
  const { t } = useTranslation();
  const { mutate: billingTerms, isLoading } = useMutation(BILLING_PAYMENT_TERMS);
  const userAccount = useSelector((state) => state.user.account);
  const { mutationProps } = CustomHook();

  const handleClick = async (value) => {
    setPaymentTerm(value.id);
    await billingTerms(
      {
        id: data.id,
        term: value.id,
      },
      mutationProps(false),
    );
    setIschanged(value.id);
  };

  const handleArray = (values) => {
    let array = paymentTermArr;
    if (values?.price_monthly_usd == '' || values?.price_monthly_usd == 0) {
      array = array.filter((item) => item.termId != 1);
    }
    if (values?.price_yearly_usd == '' || values?.price_yearly_usd == 0) {
      array = array.filter((item) => item.termId != 12);
    }
    if (values?.price_biennial_usd == '' || values?.price_biennial_usd == 0) {
      array = array.filter((item) => item.termId != 24);
    }
    if (values?.price_triennial_usd == '' || values?.price_triennial_usd == 0) {
      array = array.filter((item) => item.termId != 36);
    }
    return array;
  };

  return (
    <Styles.PAymentTermSC>
      <Styles.FirstSection>
        <div className="width">
          <Heading small name={'vs/instance.string184'} />
        </div>
        <div className="d-flex">
          <Styles.PriceTermSC>
            {handleArray(data).map((item) => (
              <div className="d-flex" key={item.id}>
                <Styles.PriceTerm onClick={() => handleClick(item)}>
                  <Checkbox
                    checkbox={Number(paymentTerm)}
                    setCheckedValue={(value) => setPaymentTerm(value)}
                    data={item}
                    onClick={() => handleClick(item)}
                  />
                  <H6>
                    <Trans
                      i18nKey={item.name}
                      values={{
                        price:
                          item.id == 1
                            ? data?.price_monthly_usd
                            : item.id == 12
                            ? data?.price_yearly_usd
                            : item.id == 24
                            ? data?.price_biennial_usd
                            : data?.price_triennial_usd,
                        currency: userAccount.account_currency,
                        currency_symbol: userAccount.account_currency_symbol,
                      }}
                      components={{ upperCaseWord: <UpperCaseWord /> }}
                    />
                  </H6>
                </Styles.PriceTerm>
                {isLoading && item.id == paymentTerm && <img src={LoadingImage} width={25} />}
              </div>
            ))}
          </Styles.PriceTermSC>
        </div>
      </Styles.FirstSection>
      <Styles.Section>
        <div className="width">
          <Heading small name={'vs/instance.string185'} />
        </div>
        <H6 className="section">{moment(data.next_payment_date).format('ll')}</H6>
      </Styles.Section>
      <Styles.Section>
        <div className="width">
          <Heading small name={'vs/instance.string186'} />
        </div>
        <H6 className="margin">
          <Trans
            i18nKey={'vs/instance.string187'}
            values={{
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
              price:
                paymentTerm == 1
                  ? data?.price_monthly_usd
                  : paymentTerm == 12
                  ? data?.price_yearly_usd
                  : paymentTerm == 24
                  ? data?.price_biennial_usd
                  : data?.price_triennial_usd,
            }}
            components={{ upperCaseWord: <UpperCaseWord /> }}
          />
        </H6>
      </Styles.Section>
      <P className="pt-4">{t('vs/instance.string183')}</P>
    </Styles.PAymentTermSC>
  );
};

export default PaymentTerms;
