import React from 'react';
import * as Styles from '../Checkout.style';
import { Trans, useTranslation } from 'react-i18next';
import { H6 } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';

const termAndCondition = [
  'public/checkout.string41',
  'public/checkout.string42',
  'public/checkout.string43',
  'public/checkout.string44',
  'public/checkout.string45',
  'public/checkout.string53',
];

const PlaceOrder = () => {
  const { t } = useTranslation();
  return (
    <Styles.PlaceOrder>
      <H6>
        <Trans i18nKey={'public/checkout.string40'}>
          <a href="https://www.serverpoint.com/tos.pdf" target="_blank" rel="noreferrer" />
        </Trans>
      </H6>
      <Styles.PlaceOrderList>
        {termAndCondition.map((data, index) => (
          <Styles.PlaceOrderSingle key={index}>
            <Icon name="circle" />
            <H6>{t(data)}</H6>
          </Styles.PlaceOrderSingle>
        ))}
      </Styles.PlaceOrderList>
    </Styles.PlaceOrder>
  );
};

export default PlaceOrder;
