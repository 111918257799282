import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MemberNavigatorContainer from 'pages/member/MemberNavigator.container';
import SignIn from 'components/sign-in';
import { ToastContainer } from 'react-toastify';
import ErrorScreen from 'components/error-screen/ErrorScreen';
import SignUp from 'components/sign-up/SignUp';
import { ForgotPasswordSet, ForgotPassword } from 'components/sign-in/components';
import { Bandwidth } from 'components/manage-bm/Bandwidth';
import PaypalSuccessScreen from 'components/PaypalSuccessScreen';
import axios from 'axios';
import { BASE_URL } from 'constants/index';
import queryString from 'query-string';
import SignupApprove from './SignupApprove';
import CCloudSignup from 'components/ccloud-signup/CCloudSignup';
import CCloudLogin from 'components/ccloud-login/CCloudLogin';
import TicketEasyView from 'components/ticket-easyview/TicketEasyView';
import { getLocalStorageValues } from 'constants/local-storage';
import { useMutation } from 'react-query';
import { TICKETS_EASYVIEW } from 'components/tickets-sign-in-pin/queries';
import theme from 'themes/light-theme';
import TicketsSignInPin from 'components/tickets-sign-in-pin/SignInWithPin';
import SignInPin from 'components/sign-in-pin/SignInWithPin';
import { Message } from 'components/alert/Message';
import { useTranslation } from 'react-i18next';
import useAuthentication from 'utils/auth-custom-hook';
import { saveUTMParamsToCookies } from 'utils/custom-hook';
import CheckoutContainer from './checkout-member/checkout/Checkout.conatiner';
import NewVSContainer from './checkout-member/vs/NewVS.container';
import { navLinks } from 'utils/nav-links-util';
import RdpaDomain from 'components/rdpa-domain';
import NotFound from 'layout/not-found/NotFound';
import AccountEmailVerify from './checkout-member/account-email-verify';
import OnetimeThankYou from './member/add-fund/onetime-thankyou/OnetimeThankYou';
import { ExpiredPassword } from 'components/sign-in/components/ExpiredPassword';
import BmCartContainer from './shoping-cart/bm-cart/BmCartContainer';
import BmCheckoutContainer from './shoping-cart/checkout/Checkout.conatiner';
import BmThankYou from './shoping-cart/thankyou/BmThankYou';
import VsThankYou from './shoping-cart/thankyou/VsThankYou';


export default function AppRouter({ queryClient }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { authenticate, logout } = useAuthentication();
  let params = queryString.parse(history?.location?.search);
  const location = useLocation();
  const { userName } = getLocalStorageValues();
  let newPath = location.pathname.includes('/easyview/');
  let ticketID = location?.pathname?.split('/easyview/')[1];
  let publicLink = location.pathname.includes('public/checkout/vs/thankyou');
  const [previousLocation, setPreviousLocation] = useState({});
  const { mutate: easyview, isLoading } = useMutation(TICKETS_EASYVIEW);

  // NEEDED TO RUN THIS API WHEN WE HAVE PLAYER ID IN THE PARAMS
  useEffect(async () => {
    if (!publicLink) {
      try {
        if (params.PayerID) await axios.post(BASE_URL + `/billing/paypal_process`, { payerid: params.PayerID });
      } finally {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('PayerID')) {
          queryParams.delete('PayerID');
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [params.PayerID]);

  useEffect(() => {
    saveUTMParamsToCookies(params);
  }, []);

  const handleOnComplete = async (data) => {
    let ticketId = localStorage.getItem('ticketId');
    await easyview(
      {
        pin: data,
        ticketId: ticketId,
      },
      {
        onSuccess: async (e) => {
          await setPreviousLocation(e);
          localStorage.setItem('pinCode', data);
          history.replace(`/tickets/easyview/${ticketId}`);
        },
        onError: (err) => {
          Message.error(t(`${err.response.data.error || err.response.data.status}`), 'Error');
        },
      },
    );
  };

  const CallEasyViewAPI = async () => {
    try {
      const response = await axios.get(BASE_URL + `/tickets/easyview/${ticketID}`);
      let { status } = response.data;
      localStorage.setItem('ticketId', ticketID);
      localStorage.removeItem('pinCode');
      if (status === 'pin_required') {
        history.push('/pin_required');
      } else if (status === 'log_in_required') {
        history.push('/');
      }
    } catch (err) {
      localStorage.removeItem('ticketId');
      Message.error(t(`${err.response.data.error || err.response.data.status}`), 'Error');
    }
  };

  const handleAuth = async () => {
    await authenticate(params.user, params.onetimepassword);
  };
  const handleLogout = async () => await logout(queryClient);

  // Check if the user is navigating to the tickets page with the parameters
  if (history.location.pathname === '/tickets' && params.user && params.onetimepassword) {
    handleAuth();
  } else if (history.location.pathname === '/logout') {
    handleLogout();
  } else if (userName?.token && newPath) {
    history.push(`/tickets/${ticketID}`);
  } else if (!userName.token && newPath && previousLocation.tickets == undefined) {
    CallEasyViewAPI();
  }

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        // limit={2}
        style={{ zIndex: 101010 }}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path={navLinks.rdap_domain_id.link} component={RdpaDomain} />
        <Route exact path={navLinks.rdap_domain.link} component={NotFound} />
        <Route exact path="/billing/paypal-cancel" component={ErrorScreen} />
        <Route exact path="/billing/paypal-thankyou" component={PaypalSuccessScreen} />
        <Route exact path="/public/checkout/vs/thankyou" component={VsThankYou} />
        <Route exact path="/public/checkout/bm/thankyou" component={BmThankYou} />
        <Route exact path="/billing/onetime_payment/thankyou" component={OnetimeThankYou} />
        <Route exact path="/account/email/verify/:id" component={AccountEmailVerify} />
        <Route exact path="/" component={() => <SignIn queryClient={queryClient} />} />
        <Route path="/bandwidth" component={Bandwidth} />
        <Route path="/bandwidth-public" component={Bandwidth} />
        <Route path="/signup" component={SignUp} />
        <Route path="/ccloud/signup" component={CCloudSignup} />
        <Route path="/ccloud/login" component={CCloudLogin} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/forgot-password-set" component={ForgotPasswordSet} />
        <Route path="/signup-approve" component={SignupApprove} />
        <Route path={navLinks['expired-password'].link} component={ExpiredPassword} />
        <Route
          path="/pin_required"
          component={() => <TicketsSignInPin handleOnComplete={handleOnComplete} isLoading={isLoading} />}
        />
        <Route path="/pin" component={SignInPin} />
        {!userName.token && newPath && (
          <Route
            path="/tickets/easyview/:id"
            component={() => (
              <TicketEasyView handleOnComplete={handleOnComplete} tickets={previousLocation} isLoading={isLoading} />
            )}
          />
        )}
        <Route
          path={navLinks.public_checkout_vs_summary.link}
          component={() => <CheckoutContainer queryClient={queryClient} />}
        />
        <Route path={navLinks.public_checkout_vs.link} component={() => <NewVSContainer queryClient={queryClient} />} />
        <Route
          path={navLinks.public_checkout_bm_summary.link}
          component={() => <BmCheckoutContainer queryClient={queryClient} />}
        />
        <Route
          path={navLinks.public_checkout_bm.link}
          component={() => <BmCartContainer queryClient={queryClient} />}
        />
        <Route path="/" component={() => <MemberNavigatorContainer queryClient={queryClient} />} />
      </Switch>
      <div id="modal-root" />
      <div id="overlay-root" />
      <div id="menu-root" />
    </ThemeProvider>
  );
}
